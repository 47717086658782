#page-map {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 80px);

  position: absolute;
  display: flex;
  overflow: hidden;
  top: 0;
}

#page-map .leaflet-container {
    z-index: 1;
}

#page-map .map-popup .leaflet-popup-tip-content {
  display: none;
}

form.landing-page-form {
    width: 500px;
    height: 100%;
    padding: 40px 40px;
    overflow-y: auto;
    position: absolute;
    right: 10px;
    z-index: 1;
  
  }

  form.landing-page-form fieldset {
    border: 0;
  }
  
  form.landing-page-form fieldset legend {
    width: 100%;
  
    font-size: 28px;
    line-height: 34px;
    color: #5c8599;
    font-weight: 700;
    padding-left: 40%;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  
  form.landing-page-form .input-block + .input-block {
    margin-top: 24px;
  }
  
  form.landing-page-form .input-block label {
    display: flex;
    color: #8fa7b3;
    margin-bottom: 8px;
    line-height: 24px;
  }
  
  form.landing-page-form .input-block label span {
    font-size: 14px;
    color: #8fa7b3;
    margin-left: 24px;
    line-height: 24px;
  }
  
  form.landing-page-form .input-block input {
    width: 100%;
    background: #f5f8fa;
    border: 1px solid #d3e2e5;
    border-radius: 20px;
    outline: none;
    color: #5c8599;
  }
  
  form.landing-page-form .input-block input {
    height: 44px;
    padding: 0 16px;
  }
  
  form.landing-page-form button.confirm-button {
    margin-top: 34px;
    width: 100%;
    height: 50px;
    border: 0;
    cursor: pointer;
    border-radius: 20px;
    color: #ffffff;
    font-weight: 800;
    transition: background-color 0.2s;
  }
  
  
/* Pop Up - Marker */

#page-map .map-popup .leaflet-popup-content-wrapper {
  background: rgba(218, 218, 218, 0.8);
  border-radius: 20px;
  box-shadow: none;
  margin-left: 325px;
  margin-bottom: -30px;
}

#page-map .map-popup .leaflet-popup-content h4 {
  color: #0089a5;
  font-size: 15px;
  font-weight: bold;
  margin: 8px 12px;
}

#page-map .map-popup .leaflet-popup-content p {
  color: #042f38;
  font-size: 12px;
  font-weight: bold;
  margin: 8px 12px;
  line-height: 15px;
}

#page-map .map-popup .leaflet-popup-tip-container {
  display: none;
}

#page-map .map-popup-you-are-here .leaflet-popup-content-wrapper {

  background: transparent;
  border-radius: 20px;
  width: 205px;
  margin-left: 150px;
  margin-top: -110px;
}

#page-map .map-popup-you-are-here .leaflet-popup-content h3 {
  color: #0089a5;
  font-size: 20px;
  font-weight: bold;
  margin: 8px 12px;
}

#page-map .map-popup-you-are-here .leaflet-popup-content p {
  color: #042f38;
  font-size: 12px;
  font-weight: bold;
  margin: 8px 12px;
  line-height: 15px;
}

#page-map .map-popup-you-are-here .leaflet-popup-tip-container {
  display: none;
}
