
.visible{
  animation: slidein 0.5s;
  visibility: visible;
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to{
    opacity: 0;
    transform: translateY(-50px);
  }
}

.hidden{
  animation: slideOut 0.5s;
  visibility: hidden;
  transition: visibility 0.3s;
}
